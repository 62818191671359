import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BasicService } from '../common/services/basic.service';
import { API_VOUCHER_URL } from '../connection.data';
import { QueryParamsModel, QueryResultsModel } from '../_base/crud';

@Injectable()
export class VoucherService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_VOUCHER_URL;
    }

    genCode(){
      const headers = {
        'Content-Type': 'application/json',
      }
      return this.http.get(`${this.baseUrl}/code/generate`, {headers});
    }
    getByCodeForLateAssignation(code: string, entity_type_id: string|number, partner_id: string|number, token: string){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return this.http.get(`${this.baseUrl}/late-assignation/code?`
            + `code=${code}&entity_type_id=${entity_type_id}&partner_id=${partner_id}`, {headers});
    }

    saleOrderAvailability(saleOrderId: string|number){
        return this.http.get(`${this.baseUrl}/availability/sale-order/${saleOrderId}`);
    }

    saleOrderUnusedVoucherAmount(saleOrderId: string|number){
        return this.http.get(`${this.baseUrl}/unused/sale-order/${saleOrderId}`);
    }

    getPage_(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${this.baseUrl}/page-old`;
        console.log(url);
        return this.http.post<QueryResultsModel>(url, queryParams);
    }

    lateAssignation(item: any, token: string){
        const headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return this.http.post(`${this.baseUrl}/late-assignation/unitary`, item, {headers});
    }

    massiveLateAssignation(item: any, token: string){
        console.dir(Array.from(item.entries()));
        const headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        return this.http.post(`${this.baseUrl}/late-assignation/massive`, item, {headers});
    }

    validateMassiveLateAssignation(item: any, token: string){
        const headers = {
            // 'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        console.dir(Array.from(item.entries()));
        return this.http.post(`${this.baseUrl}/late-assignation/massive/validation`, item, {headers});
    }

    isProcessingMassiveBatchLoad(token: string): Observable <any>{
        return this.http.get<any>(`${this.baseUrl}/late-assignation/massive/processing/${token}`);
    }
}
